var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"ml-10 mr-10 mt-10 mb-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Mother Site Order")])])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.admin_status == 'Pending')?_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.Approve(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-marked-circle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.CancelProduct(item)}}},[_c('v-icon',{staticClass:"pa-2",attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1)],1):_c('div',[_c('p',{staticClass:"mb-0 font-weight-medium text-left"},[_vm._v(" "+_vm._s(item.admin_status)+" ")])])]}},{key:"item.showInvoice",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.viewchInvoice(item)}}},[_vm._v(" Invoice ")])]}}])}),_c('v-row',{attrs:{"justify":"center"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"750px","scrollable":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',{staticClass:"d-inline-block text-truncate ml-0",staticStyle:{"max-width":"250px"}},[_vm._v(_vm._s(_vm.editedItem.product_name))]),_c('v-toolbar-title',{staticClass:"mx-3"},[_vm._v("Quantity: "+_vm._s(_vm.editedItem.product_quantity))]),_c('v-toolbar-title',{staticClass:"mx-3"},[_vm._v("Remain: "+_vm._s(_vm.editedItem.product_quantity - (Number(_vm.totalSubtractCountOfWarhouse) + Number(_vm.totalSubtractCountOfShop)))+" "),(
                _vm.editedItem.product_quantity -
                  (Number(_vm.totalSubtractCountOfWarhouse) +
                    Number(_vm.totalSubtractCountOfShop)) <
                0
              )?_c('span',{staticClass:"red--text font-weight-medium ml-4",staticStyle:{}},[_vm._v("Limit crossed")]):_vm._e()]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","elevation":"0","small":"","disabled":_vm.editedItem.product_quantity !=
              Number(_vm.totalSubtractCountOfWarhouse) +
                Number(_vm.totalSubtractCountOfShop)},on:{"click":_vm.confirmProduct}},[_c('p',{staticClass:"green--text mb-0 font-weight-medium text-body-2"},[_vm._v(" Confirm ")])])],1),_c('v-divider'),_c('v-card-title',{staticClass:"py-2 px-0 font-weight-bold"},[_vm._v("Warehouse")]),_c('v-divider'),_c('v-simple-table',{attrs:{"fixed-header":"","height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-center"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-center"},[_vm._v("Subtract")])])]),_c('tbody',_vm._l((_vm.warehouse),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.quantity))]),_c('td',{staticClass:"d-flex justify-center"},[_c('v-form',{attrs:{"lazy-validation":""}},[_c('v-text-field',{staticClass:"mt-1",staticStyle:{"max-width":"100px","height":"50px"},attrs:{"outlined":"","type":"number","flat":"","dense":"","rules":[
                        function (v) {
                          if (Number(v) > Number(item.quantity)) {
                            return 'Limit crossed';
                          }
                          return true;
                        } ]},on:{"input":function($event){return _vm.warhouseSubtract(item)}},model:{value:(item.subtract),callback:function ($$v) {_vm.$set(item, "subtract", $$v)},expression:"item.subtract"}})],1)],1)])}),0)]},proxy:true}])}),_c('v-divider'),_c('v-card-title',{staticClass:"py-2 px-0 font-weight-bold"},[_vm._v("Shop")]),_c('v-divider'),_c('v-simple-table',{attrs:{"fixed-header":"","height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-center"},[_vm._v("Quantity")]),_c('th',{staticClass:"text-center"},[_vm._v("Subtract")])])]),_c('tbody',_vm._l((_vm.shop),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.quantity))]),_c('td',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticClass:"mt-1",staticStyle:{"max-width":"100px","height":"50px"},attrs:{"outlined":"","type":"number","flat":"","dense":"","rules":[
                      function (v) {
                        if (Number(v) > Number(item.quantity)) {
                          return 'Limit crossed';
                        }
                        return true;
                      } ]},on:{"input":function($event){return _vm.shopSubtract(item)}},model:{value:(item.subtract),callback:function ($$v) {_vm.$set(item, "subtract", $$v)},expression:"item.subtract"}})],1)])}),0)]},proxy:true}])})],1)],1),_c('v-snackbar',{attrs:{"centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="products"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <p class="mb-0"> Mother Site Order</p>
                    <!-- <v-row>
                      <v-col
                        cols="12"
                        class="pa-10 mx-10 d-flex flex-row justify-space-around align-center"
                      >
                        <p class="mt-2">Order ID: {{ orderInfo.id }}</p>
                        <p class="mt-2">
                          Payment Status: {{ orderInfo.order_status }}
                        </p>
                        <p class="mt-2">
                          Delivery Status: {{ orderInfo.delivery_status }}
                        </p>
                        <p class="mt-2" v-if="orderInfo.user_id != -1">
                          Site Name: {{ orderInfo.user_id }}
                        </p>
                        <p class="mt-2" v-else>
                          Non Verified ID: {{ orderInfo.non_verified_user_id }}
                        </p>

                        <div class="d-flex flex-row">
                          <v-btn
                            v-if="showConfirmButton"
                            style="float: right"
                            color="green darken-1"
                            @click="orderConfirm"
                            elevation="0"
                          >
                            Confirm Order
                          </v-btn>
                          <v-spacer></v-spacer>

                          <v-btn
                            v-if="
                              showCancelButton && orderStatus != 'Cancelled'
                            "
                            style="float: right"
                            color="red"
                            dark
                            @click="orderCancel"
                            elevation="0"
                          >
                            Cancel Order
                          </v-btn>
                        </div>

                        <p class="mb-0" v-if="orderStatus == 'Confirmed'">
                          Order Status :
                          <span class="font-weight-medium">Approved</span>
                        </p>
                        <p class="mb-0" v-if="orderStatus == 'Cancelled'">
                          Order Status :
                          <span class="font-weight-medium">Cancelled</span>
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row> -->
                  </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div v-if="item.admin_status == 'Pending'">
                    <v-btn icon @click="Approve(item)">
                      <v-icon color="primary"
                        >mdi-checkbox-marked-circle</v-icon
                      >
                    </v-btn>

                    <v-btn icon @click="CancelProduct(item)">
                      <v-icon color="red" class="pa-2">mdi-close-circle</v-icon>
                    </v-btn>
                  </div>

                  <div v-else>
                    <p class="mb-0 font-weight-medium text-left">
                      {{ item.admin_status }}
                    </p>
                  </div>
                </template>
                <template v-slot:item.showInvoice="{ item }" >

                  <v-btn text @click="viewchInvoice(item)" color="primary"
>
                    Invoice
                  </v-btn>
                </template>
              </v-data-table>

              <v-row justify="center"> </v-row>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- 
    <v-row v-else> Not loaded </v-row> -->

    <v-dialog v-model="menu" width="750px" scrollable>
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title
            class="d-inline-block text-truncate ml-0"
            style="max-width: 250px"
            >{{ editedItem.product_name }}</v-toolbar-title
          >

          <v-toolbar-title class="mx-3"
            >Quantity: {{ editedItem.product_quantity }}</v-toolbar-title
          >

          <v-toolbar-title class="mx-3"
            >Remain:
            {{
              editedItem.product_quantity -
              (Number(totalSubtractCountOfWarhouse) +
                Number(totalSubtractCountOfShop))
            }}
            <span
              class="red--text font-weight-medium ml-4"
              style="border 1px white"
              v-if="
                editedItem.product_quantity -
                  (Number(totalSubtractCountOfWarhouse) +
                    Number(totalSubtractCountOfShop)) <
                0
              "
              >Limit crossed</span
            ></v-toolbar-title
          >

          <v-spacer></v-spacer>

          <v-btn
            color="white"
            elevation="0"
            small
            @click="confirmProduct"
            :disabled="
              editedItem.product_quantity !=
              Number(totalSubtractCountOfWarhouse) +
                Number(totalSubtractCountOfShop)
            "
          >
            <p class="green--text mb-0 font-weight-medium text-body-2">
              Confirm
            </p>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
        <v-card-title class="py-2 px-0 font-weight-bold"
          >Warehouse</v-card-title
        >
        <v-divider></v-divider>
        <v-simple-table fixed-header height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-center">Quantity</th>
                <th class="text-center">Subtract</th>
                <!-- <th class="text-left">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in warehouse" :key="index">
                <td>{{ item.name }}</td>
                <td class="text-center">{{ item.quantity }}</td>
                <td class="d-flex justify-center">
                  <v-form lazy-validation>
                    <v-text-field
                      outlined
                      type="number"
                      style="max-width: 100px; height: 50px"
                      class="mt-1"
                      flat
                      dense
                      v-model="item.subtract"
                      @input="warhouseSubtract(item)"
                      :rules="[
                        (v) => {
                          if (Number(v) > Number(item.quantity)) {
                            return 'Limit crossed';
                          }
                          return true;
                        },
                      ]"
                    ></v-text-field>
                  </v-form>
                </td>
               
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider></v-divider>
        <v-card-title class="py-2 px-0 font-weight-bold">Shop</v-card-title>
        <v-divider></v-divider>

        <v-simple-table fixed-header height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-center">Quantity</th>
                <th class="text-center">Subtract</th>
                <!-- <th class="text-left">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in shop" :key="index">
                <td>{{ item.name }}</td>
                <td class="text-center">{{ item.quantity }}</td>
                <td class="d-flex justify-center">
                  <v-text-field
                    outlined
                    type="number"
                    style="max-width: 100px; height: 50px"
                    class="mt-1"
                    flat
                    dense
                    v-model="item.subtract"
                    @input="shopSubtract(item)"
                    :rules="[
                      (v) => {
                        if (Number(v) > Number(item.quantity)) {
                          return 'Limit crossed';
                        }
                        return true;
                      },
                    ]"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    loading: true,
    ripple: false,
    menu: false,
    showCancelButton: false,
    showConfirmButton: false,
    product_name: "",
    product_quantity: 0,
    product_id: -1,
    search: "",
    quantity_value: "",
    headers: [
      { text: "Order ID", value: "id", sortable: false },
      { text: "Invoice ID", value: "invoice_id", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },
      { text: "Delivery Status", value: "delivery_status", sortable: false },
      { text: "", value: "showInvoice", sortable: false },
      // { text: "Point", value: "total_point", sortable: false },
      // { text: "Size", value: "product_size", sortable: false },
      // { text: "Color", value: "product_color", sortable: false },
      // { text: "Admin Confirmation", value: "actions", sortable: false },
      // // { text: "Status", value: "actions", sortable: false },
      // { text: "Status", value: "admin_status", sortable: false },
    ],
    products: [],
    demo: [],
    contact: [],
    useraddress: [],
    orderInfo: {},
    editedItem: {
      product_name: "",
      product_quantity: 0,
      id: -1,
    },
    orderStatus: "",

    warehouse: [],

    shop: [],

    subtract: 0,
    subtractWarhouse: [],
    subtractShop: [],
    totalSubtractCountOfWarhouse: "0",
    totalSubtractCountOfShop: "0",

    snackbar: false,
    text: "",
  }),

  watch: {
    subtractWarhouse: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfWarhouse",
    },

    subtractShop: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfShop",
    },

    menu: function (newValue) {
      this.totalSubtractCountOfWarhouse = "0";
      this.totalSubtractCountOfShop = "0";
      this.subtractWarhouse.length = 0;
      this.subtractShop.length = 0;
    },
  },

  methods: {
    initialize() {
      axios
        .get("Cart/show_purchase_invoices/")
        .then((response) => {
        this.products=response.data.data
          this.product_id = this.$route.query.id;
          this.unit = this.$route.unit;
          this.product_title = response.data.product_title;
          this.loading = false;

          
        });
    },
    viewchInvoice(item)
    {
      // console.log("gtnhlkltkr" , item)
       this.$router.push({
          path: "/dashboard/pages/mother-purchase-invoices",
          query: { id: item.id },
        });
    },
    confirmProduct(){

    },
   
  },
  mounted(){
    this.initialize();
  }

};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}

.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>


